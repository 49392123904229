export default {
  user: (state) => {
    if (typeof state.user === 'string') {
      return JSON.parse(state.user);
    }
    return state.user;
  },
  accessToken: (state) => state.accessToken,
  // services: () => [],
  services: (state) => {
    if (typeof state.services === 'object') return state.services;
    if (typeof state.services !== 'undefined' && state.services.length) return JSON.parse(state.services);
    return [];
  },
};
