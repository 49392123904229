export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_SERVICES = 'SET_SERVICES';

export default {
  SET_TOKEN,
  REMOVE_TOKEN,
  SET_USER,
  SET_SERVICES,
};
