<template>
  <a-layout id="components-layout-trigger" style="min-height:100vh">
    <a-layout-sider v-model="collapsed" width="300" :trigger="null" collapsible>
      <router-link :to="{ name: 'home' }">
        <div class="logo">
          <img v-if="!collapsed" src="https://bookhara.uz/design/logo-w.png" alt="">
          <img v-else src="https://bookhara.uz/design/fav.svg" alt="" style="width: auto" class="mobile">
        </div>
      </router-link>
      <MenuComponent></MenuComponent>
    </a-layout-sider>
    <a-layout>
      <HeaderComponent></HeaderComponent>
      <transition name="page" mode="out-in">
        <slot>
          <router-view />
        </slot>
      </transition>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderComponent from './partials/HeaderComponent.vue';
import MenuComponent from './partials/MenuComponent.vue';

export default {
  name: 'DefaultLayout',
  computed: mapGetters(['collapsed']),
  props: {
    mainTitle: {
      type: String,
      required: false,
    },
    icon: {
      required: false,
      default: '<Icon type="arrow-left" />',
    },
  },
  data() {
    return {};
  },
  components: {
    HeaderComponent,
    MenuComponent,
  },
};
</script>

<style>
#components-layout-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-trigger .logo {
    display: flex;
    justify-content: center;
    padding: .75em 1em;
}

.logo img {
  width: 200px;
  display: 0 auto;
}
</style>
