<template>
  <a-layout-header
      style="background: #fff; padding: 0;display: flex;">
    <a-icon
        class="trigger"
        :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        @click="toggleCollapsed"/>
    <template slot="backIcon">
      <Icon type="user" />
    </template>
    <a-dropdown v-if="user"  style="margin-left: auto" placement="bottomLeft">
        <span style="margin-right:24px">
          <a-badge :dot="true" :count="1"
                   :number-style="{ backgroundColor: '#108ee9' }">
                <a-avatar
                    shape="square"
                    size="large"
                    :style="{ backgroundColor: '#108ee9', verticalAlign: 'middle' }"
                >
              {{ user.username.charAt(0).toUpperCase() }}
            </a-avatar>
          </a-badge>
          <span style="margin-left: 1em; text-transform: capitalize">{{ user.username }}</span>
        </span>
      <a-menu slot="overlay">
        <a-menu-item @click="toSettings">
          <a-icon type="setting" />
          Настройки
        </a-menu-item>
        <a-menu-item v-on:click="logout">
          <a-icon type="logout" />
          Выйти
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </a-layout-header>
</template>
<script>
import { mapGetters } from 'vuex';
import { Icon } from 'ant-design-vue';

export default {
  computed: {
    ...mapGetters({ user: 'user/user', collapsed: 'collapsed' }),
  },
  mounted() {
    // this.setServices();
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push({ path: '/login' });
    },
    toggleCollapsed() {
      this.$store.commit('SET_COLLAPSED', !this.collapsed);
    },
    toSettings() {
      this.$router.push({ name: 'settings' });
    },
  },
  components: {
    Icon,
  },
};
</script>
