import Vue from 'vue';
import Vuex from 'vuex';
import auth from '../modules/auth';
import user from '../modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    collapsed: false,
    updateData: false,
  },
  getters: {
    collapsed: (state) => state.collapsed,
    updateData: (state) => state.updateData,
  },
  mutations: {
    SET_COLLAPSED: (state, collapse) => {
      state.collapsed = collapse;
    },
    MAKE_UPDATE: (state, update) => {
      state.updateData = update;
    },
  },
  modules: {
    auth,
    user,
  },
});
