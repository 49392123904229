function page(path) {
  return () => import(/* webpackChunkName: '' */ `../views/${path}`).then((m) => m.default || m);
}

export default [
  {
    path: '/',
    name: 'home',
    component: page('HomeView'),
    show: true,
    icon: 'home',
    meta: {
      title: 'Панель управление',
    },
  },
  {
    path: '/reports',
    name: 'reports',
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    show: false,
    icon: 'pie-chart',
    meta: {
      title: 'Отчёты',
      // middleware: [guest],
    },
  },
  {
    path: '/providers',
    name: 'providers',
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    show: false,
    icon: 'appstore',
    meta: {
      title: 'Поставщики',
      // middleware: [guest],
    },
  },
  {
    path: '/orders',
    name: 'orders',
    // component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    show: false,
    icon: 'shopping-cart',
    meta: {
      title: 'Заказы',
      // middleware: [guest],
    },
  },
  {
    path: '/users',
    show: true,
    showChildren: false,
    component: page('users/index.vue'),
    name: 'users',
    icon: 'team',
    meta: {
      title: 'Клиенты',
    },
  },
  {
    path: '/users/:uuid',
    name: 'user-show',
    show: false,
    component: page('users/show.vue'),
    meta: {
      title: 'Пользователь',
    },
  },
  {
    path: '/services/insurance',
    name: 'insurance',
    show: false,
    component: page('services/insurance.vue'),
    meta: {
      title: 'Страхование',
    },
  },
  {
    path: '/services/railways',
    name: 'railways',
    show: false,
    component: page('services/railways.vue'),
    meta: {
      title: 'Железная Дорога',
    },
  },
  {
    path: '/billing',
    name: 'billing',
    show: true,
    component: page('billing/transactions.vue'),
    icon: 'transaction',
    meta: {
      title: 'Биллинг',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: page('SettingView.vue'),
    show: false,
    icon: 'setting',
    meta: {
      title: 'Настройки',
      // middleware: [guest],
    },
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: page('SettingView.vue'),
    show: false,
    icon: 'mobile',
    meta: {
      title: 'Устройсва',
      // middleware: [guest],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: page('LoginView'),
    meta: {
      title: 'Авторизация',
      // middleware: [guest],ç
    },
  },
  {
    path: '/forbidden',
    name: '403',
    component: () => import('../views/errors/NotFound.vue'),
    meta: {
      title: 'В доступе отказано',
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/errors/NotFound.vue'),
    meta: {
      title: 'Страница не найдено',
    },
  },
];
