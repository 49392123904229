import Cookies from 'js-cookie';
import * as MUTATION from './mutation-types';

export default {
  [MUTATION.SET_TOKEN](state, { token }) {
    state.accessToken = token;
    Cookies.set('access_token', token);
  },
  [MUTATION.REMOVE_TOKEN](state) {
    state.accessToken = false;
    Cookies.remove('user');
    Cookies.remove('services');
    Cookies.remove('access_token');
  },
  [MUTATION.SET_USER](state, payload) {
    state.user = payload;
    Cookies.set('user', JSON.stringify(payload));
  },
  [MUTATION.SET_SERVICES](state, payload) {
    state.services = payload;
    Cookies.set('services', JSON.stringify(payload), { expires: 1 });
  },
};
