import store from '../../store';

export default async (to, from, next) => {
  const user = store.getters['user/accessToken'];
  if (!user && user) {
    try {
      await store.dispatch('user/accessToken');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  next();
};
