import Vue from 'vue';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import Meta from 'vue-meta';
import store from '../store';
import routes from './routes';

Vue.use(Meta);
Vue.use(Router);

// The middleware for every page of the application.
const globalMiddleware = ['check-auth'];

// Load middleware modules dynamically.
const routeMiddleware = resolveMiddleware(require.context('./middleware', false, /.*\.js$/));

const router = createRouter();

sync(store, router);

export default router;

/**
 * Scroll Behavior
 */
function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    return { selector: to.hash };
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1);

  if (component && component.scrollToTop === false) {
    return {};
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        x: 0,
        y: 0,
      });
    }, 190);
  });
}

/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const routerInstance = new Router({
    scrollBehavior,
    mode: 'history',
    routes,
  });
  routerInstance.beforeEach(beforeEach);
  routerInstance.afterEach(afterEach);

  return routerInstance;
}

/**
 * Call each middleware.
 */
function callMiddleware(middleware, to, from, next) {
  const stack = middleware.reverse();

  // eslint-disable-next-line consistent-return,no-underscore-dangle
  const _next = (...args) => {
    // Stop if "_next" was called with an argument or the stack is empty.
    if (args.length > 0 || stack.length === 0) {
      return next(...args);
    }

    const {
      middleware,
      params,
    } = parseMiddleware(stack.pop());

    if (typeof middleware === 'function') {
      middleware(to, from, _next, params);
    } else if (routeMiddleware[middleware]) {
      routeMiddleware[middleware](to, from, _next, params);
    } else {
      throw Error(`Undefined middleware [${middleware}]`);
    }
  };

  _next();
}

/**
 * Merge the global middleware with the components' middleware.
 *
 * @param  {Array} components
 * @return {Array}
 */
function getMiddleware(components) {
  const middleware = [...globalMiddleware];
  components.filter((c) => c.middleware).forEach((component) => {
    if (Array.isArray(component.middleware)) {
      middleware.push(...component.middleware);
    } else {
      middleware.push(component.middleware);
    }
  });

  return middleware;
}

/**
 * Resolve async components.
 */
function resolveComponents(components) {
  return Promise.all(components.map((component) => (typeof component === 'function' ? component() : component)));
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
async function beforeEach(to, from, next) {
  let components = [];

  try {
    // Get the matched components and resolve them.
    components = await resolveComponents(
      router.getMatchedComponents({ ...to }),
    );
  } catch (error) {
    if (/^Loading( CSS)? chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload(true);
      return;
    }
  }

  if (components.length === 0) {
    // eslint-disable-next-line consistent-return
    return next();
  }

  // Get the middleware for all the matched components.
  const middleware = getMiddleware(components);

  // Load async data for all the matched components.
  await asyncData(components);

  // Call each middleware.
  callMiddleware(middleware, to, from, (...args) => {
    // Set the application layout only if "next()" was called with no args.
    if (args.length === 0) {
      router.app.setLayout(components[0].layout || '');
    }

    next(...args);
  });
}

async function asyncData(components) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < components.length; i++) {
    const component = components[i];

    if (!component.asyncData) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const dataFn = component.data;

    try {
      const asyncData = await component.asyncData();
      component.data = () => ({
        ...(dataFn ? dataFn.apply(this) : {}),
        ...asyncData,
      });
    } catch (e) {
      component.layout = 'error';

      // eslint-disable-next-line no-console
      console.error('Failed to load asyncData', e);
    }
  }
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
// eslint-disable-next-line no-unused-vars
async function afterEach(to, from, next) {
  await router.app.$nextTick();
}

/**
 * @param  {String|Function} middleware
 * @return {Object}
 */
function parseMiddleware(middleware) {
  if (typeof middleware === 'function') {
    return { middleware };
  }

  const [name, params] = middleware.split(':');

  return {
    middleware: name,
    params,
  };
}

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
function resolveMiddleware(requireContext) {
  return requireContext.keys()
    .map((file) => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
    .reduce((guards, [name, guard]) => (
      {
        ...guards,
        [name]: guard.default,
      }
    ), {});
}
