<template>
  <a-menu
    :default-selected-keys="activeMenu"
    :default-open-keys="activeMenu"
    theme="dark"
    mode="inline">
    <template v-for="menu in menuItems">
      <a-sub-menu :key="menu.name" v-if="menu.children">
        <span slot="title">
          <a-icon :type="menu.icon"/>
          <span>{{ menu.meta.title }}</span>
        </span>
        <template v-for="child in onlyAvailable(menu.children)">
          <a-sub-menu :key="child.name" v-if="child.children">
          <span slot="title">
            <a-icon :type="child.icon"/>
            <span>{{ child.meta.title }}</span>
          </span>
            <a-menu-item v-for="childItem in onlyAvailable(child.children)" :key="childItem.name">
              <router-link
                :to="{ name: childItem.name }"
                :key="childItem.name"
              >
                <a-icon v-if="childItem.icon" :type="childItem.icon"/>
                <span>{{ childItem.meta.title }}</span>
              </router-link>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item v-else :key="child.name">
            <router-link :to="child.path">
              <a-icon :type="child.icon"></a-icon>
              <span>{{ child.meta.title }}</span>
            </router-link>
          </a-menu-item>
        </template>
      </a-sub-menu>
      <a-menu-item v-else :key="menu.name">
        <router-link :to="{ name: menu.name }">
          <a-icon :type="menu.icon"/>
          <span>{{ menu.meta.title }}</span>
        </router-link>
      </a-menu-item>
    </template>
  </a-menu>
</template>
<script>
import _ from 'lodash';
import routes from '../../router/routes';

export default {
  data() {
    return {
      menus: routes,
      dynamicRoutes: [],
      services: [],
    };
  },
  computed: {
    menuItems() {
      return this.onlyAvailable(this.menus);
    },
    activeMenu() {
      const routeKeys = _.map(this.$router.currentRoute.matched, 'name');
      return _.uniq(routeKeys);
    },
  },
  created() {
    this.services = this.$store.getters['user/services'];
    if (!this.services.length) {
      this.$api.getAccount(({ data }) => {
        this.services = data.data.services.map((service) => ({
          name: service.name,
          slug: service.slug,
        }));
        this.$store.dispatch('user/setServices', this.services);
        this.applyMenu();
      });
    } else {
      this.applyMenu();
    }
  },
  methods: {
    applyMenu() {
      const servicesMenu = this.services.map((i) => ({
        icon: '',
        path: `/services/${i.slug}`,
        show: true,
        meta: {
          title: i.name,
        },
      }));
      this.menus.push({
        icon: 'appstore',
        path: '',
        show: true,
        meta: {
          title: 'Сервисы',
        },
        children: servicesMenu,
      });
    },
    onlyAvailable(items) {
      return items.filter((i) => typeof i.show === 'boolean' && i.show === true);
    },
  },
};
</script>
